import React from "react";
import { Col, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  SiVisualstudiocode,
  SiPostman,
  SiSlack,
  SiMacos,
  SiLinux,
  SiWindows,
} from "react-icons/si";

function Toolstack() {
  const tooltips = {
    MacOS: "MacOS",
    Linux: "Linux",
    Windows: "Windows",
    VSCode: "Visual Studio Code",
    Postman: "Postman",
    Slack: "Slack",
  };

  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="tooltip-macos">{tooltips.MacOS}</Tooltip>}
      >
        <Col xs={4} md={2} className='tech-icons'>
          <SiMacos />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="tooltip-linux">{tooltips.Linux}</Tooltip>}
      >
        <Col xs={4} md={2} className='tech-icons'>
          <SiLinux />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="tooltip-windows">{tooltips.Windows}</Tooltip>}
      >
        <Col xs={4} md={2} className='tech-icons'>
          <SiWindows />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="tooltip-vscode">{tooltips.VSCode}</Tooltip>}
      >
        <Col xs={4} md={2} className='tech-icons'>
          <SiVisualstudiocode />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="tooltip-postman">{tooltips.Postman}</Tooltip>}
      >
        <Col xs={4} md={2} className='tech-icons'>
          <SiPostman />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="tooltip-slack">{tooltips.Slack}</Tooltip>}
      >
        <Col xs={4} md={2} className='tech-icons'>
          <SiSlack />
        </Col>
      </OverlayTrigger>
    </Row>
  );
}

export default Toolstack;
