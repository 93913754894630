import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";

import betterplace from "../../Assets/Projects/betterplace.png";
import minds from "../../Assets/Projects/99minds.png";
import burger from "../../Assets/Projects/burger.png";
import portfolio from "../../Assets/Projects/portfolio.png";
import npmPackages from "../../Assets/Projects/npmPackages.png";






function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        <Col md={4} className="project-card">
            <ProjectCard
              imgPath={npmPackages}
              title="NPM packages"
              description="Introducing this ultimate website for searching npm packages. Easily find and explore the npm packages you need, all in one place. Our intuitive search functionality helps you quickly discover the right packages to enhance your projects, saving you time and effort."
              demoLink="https://search-npm-packages.netlify.app/"
              ghLink="https://github.com/vikram2580/npm-packages"
            />
          </Col>
        <Col md={4} className="project-card">
            <ProjectCard
              imgPath={portfolio}
              title="My Portfolio"
              description="Welcome to my digital haven! I'm Vikram Singh, a passionate creator crafting compelling visual experiences. Explore a world where design meets innovation, each project a testament to my commitment to excellence."
              demoLink="https://vikramsingh70.com/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={betterplace}
              title="BetterPlace"
              description="BetterPlace is Asia’s largest human capital Saas platform that enables enterprises to manage the entire
              lifecycle of their frontline workers"
              demoLink="https://platform.betterplace.co.in/"
              ghLink = ""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={minds}
              title="99Minds"
              description="Enrich your Customer Journey with a Gift Card, Store Credit and Loyalty Program. Empower them to share
              your brand experience with Referral. "
              demoLink="https://www.99minds.io/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={burger}
              title="My Burger"
              description="Discover the perfect burger for every craving! Our app brings together a world of delicious options."
              ghLink="https://github.com/vikram2580/my-burger-app"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
