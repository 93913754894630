import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className='quote-card-view'>
      <Card.Body>
        <blockquote className='blockquote mb-0'>
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className='purple'>Vikram Singh </span>
            from <span className='purple'> Rajasthan, India.</span>
            <br />
            I hold a postgraduate degree in Information Technology from Panjab
            University, India.
            <br />
            Currently, I am pursuing my studies in Blockchain Development at
            George Brown College in Toronto, Canada.
            <br />
            I have a strong passion for enhancing my coding skills and creating
            applications and websites. My focus lies in developing WebApps.
            <br />
            <br />
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className='about-activity'>
              <ImPointRight /> Playing Games
            </li>
            <li className='about-activity'>
              <ImPointRight /> Cooking
            </li>
            <li className='about-activity'>
              <ImPointRight /> Travelling
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Empowering Innovation with Code and Tech Expertise"{" "}
          </p>
          <footer className='blockquote-footer'>Vikram Singh</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
