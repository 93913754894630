import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiGit,
} from "react-icons/di";
import {
  SiSass,
  SiFirebase,
  SiNextdotjs,
  SiBitbucket,
  SiTailwindcss,
  SiBootstrap,
  SiGraphql,
  SiGatsby,
  SiHtml5,
  SiCss3,
  SiJira as SiJiraIcon,
  SiJenkins as SiJenkinsIcon,
} from "react-icons/si";

function Techstack() {

  // Map of icons to their corresponding names
  const iconsMap = {
    DiJavascript1: {
      icon: <DiJavascript1 />,
      name: "JavaScript",
    },
    DiReact: {
      icon: <DiReact />,
      name: "React",
    },
    SiNextdotjs: {
      icon: <SiNextdotjs />,
      name: "Next.js",
    },
    DiNodejs: {
      icon: <DiNodejs />,
      name: "Node.js",
    },
    SiGatsby: {
      icon: <SiGatsby />,
      name: "Gatsby",
    },
    SiGraphql: {
      icon: <SiGraphql />,
      name: "GraphQL",
    },
    SiHtml5: {
      icon: <SiHtml5 />,
      name: "HTML5",
    },
    SiCss3: {
      icon: <SiCss3 />,
      name: "CSS3",
    },
    SiSass: {
      icon: <SiSass />,
      name: "Sass",
    },
    SiTailwindcss: {
      icon: <SiTailwindcss />,
      name: "Tailwind CSS",
    },
    SiBootstrap: {
      icon: <SiBootstrap />,
      name: "Bootstrap",
    },
    SiFirebase: {
      icon: <SiFirebase />,
      name: "Firebase",
    },
    DiGit: {
      icon: <DiGit />,
      name: "Git",
    },
    SiBitbucket: {
      icon: <SiBitbucket />,
      name: "Bitbucket",
    },
    SiJiraIcon: {
      icon: <SiJiraIcon />,
      name: "Jira",
    },
    SiJenkinsIcon: {
      icon: <SiJenkinsIcon />,
      name: "Jenkins",
    },
  };

  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      {Object.keys(iconsMap).map((key, index) => (
        <Col key={index} xs={4} md={2} className="tech-icons">
         
            <div>
              {iconsMap[key].icon}
              <div style={{ textAlign: "center", fontSize: "12px" }}>{iconsMap[key].name}</div>
            </div>
        </Col>
      ))}
    </Row>
  );
}

export default Techstack;
